function setNewInputImage(img : File, key : number) {
    const pictoDisplay = document.getElementById(`currentPicto${key}`) as HTMLImageElement;
    pictoDisplay.src = URL.createObjectURL(img);
}

function setNewSelectImage(img : string, key : number) {
    const pictoInput = document.getElementById(`picto_input${key}`) as HTMLInputElement;
    if (img === "noChange" || pictoInput.files!.length > 0) {
        return;
    }
    const pictoDisplay = document.getElementById(`currentPicto${key}`) as HTMLImageElement;
    pictoDisplay.src = "https://rdlfbingo.dev.mtv-nprd.cloud.bpifrance.fr/images/" + img;
}

export { setNewInputImage, setNewSelectImage };