import { useEffect, useState } from 'react';
import './AppBack.scss';
import SegmentForm, { getNewSegmentsInfos } from '../SegmentForm/SegmentForm';
import { SegmentInfo } from '../../Type/SegmentInfo';
import { backendURL } from '../../Config/vars';
import bin from '../../Resources/bin.png';
import trash from '../../Resources/trash.png';
import refresh from '../../Resources/refresh.png';
import wheel from '../../Resources/wheel.png';

async function sendNewSegments(newSegments: SegmentInfo[]) {
  try {
    const sendResponse = await fetch(backendURL + "/updateSegments", {
      method: "POST",
      headers: {
        'Content-Type': "application/json",
      },
      body: JSON.stringify(newSegments)
    });
    if (sendResponse.status === 400) {
      alert('Des champs ont été oubliés ou bien il n\'y a pas assez de segments renseignés');
    }
    else if (sendResponse.status !== 200) {
      throw new Error('error trying to send segments');
    }
    else {
      alert('La roue a correctement été modifiée');
    }
  } catch (e) {

    alert('Une erreur s\'est produite lors de l\'envoi des segments, veuillez patienter et réessayer');
  }
}

function AppBack() {
  document.body.classList.add("appback-bg-color");

  const [existingImages, setExistingImages] = useState([] as string[]);
  const [segmentForms, setSegmentForms] = useState([] as JSX.Element[]);
  const [key, setKey] = useState(0);

  useEffect(() => {
    fetch(backendURL + "/getImages", {
      method: "GET",
      headers: {
        'Content-Type': "application/json",
      }
    }).then((res) => {
      return res.text()
    }).then((body) => {
      setExistingImages(JSON.parse(body));
    });
  }, []);

  const loadKey = () => {
    setKey(key + 1);
    return key;
  }

  return (
    <>
      <div className="appback-header">

        <button className="header-button" id='refreshButton' onClick={async () => {
          try {
            // api call to get the data
            const segRes = await fetch(backendURL + "/getSegments", {
              method: "GET",
              headers: {
                'Content-Type': "application/json",
              }
            });
            const body: SegmentInfo[] = JSON.parse(await segRes.text());
            // turn the returned list into proper segmentForms
            setSegmentForms(body.map((el, index) => {
              return SegmentForm(loadKey() + index + 1, existingImages, el);
            }));
            setKey(key + body.length + 1);
          } catch (e) {
            console.log("segments request failed:", e);
          }
        }}>
          <img src={refresh} alt="" />
          Refresh
        </button>

        <button className="header-button" id='rasleculbutton' onClick={() => { setSegmentForms([]) }}>
          <img src={bin} alt="" />
          Reset
        </button>

        <button className="header-button" id='sendButton' onClick={async () => {
          // we send a message of type "backToFront" so the front knows it's an update of his segments
          getNewSegmentsInfos(segmentForms).then((infos) => {
            if (infos.length === 0) {
              return;
            }
            sendNewSegments(infos);
          });
        }}>
          <img src={wheel} alt="" />
          Envoyer
        </button>

      </div>

      <button className="add-button" id='plusRow' onClick={() => {
        setSegmentForms(old => [...old, SegmentForm(loadKey(), existingImages)]);
      }}>
        +
      </button>

      <div className='cards-container'>
        {
          segmentForms.map((el, index) => {
            return (
              <div className="form-card-container" key={index}>
                {el}
                <button className='delete-button' onClick={() => {
                  setSegmentForms(old => old.filter((_, i) => i !== index));
                }}>
                  <img src={trash} alt="" />
                </button>
              </div>
            )
          })
        }
      </div>
    </>
  )
}

export default AppBack
