import { SegmentInfo } from "../../Type/SegmentInfo";
import "./SegmentForm.scss";
import { setNewInputImage, setNewSelectImage } from "./SegmentForm.utils";
import pngPlaceholder from "../../Resources/input-image.png";

export async function getNewSegmentsInfos(segmentForms: JSX.Element[]): Promise<SegmentInfo[]> {
  const res = [] as SegmentInfo[];

  if (!checkInputs(segmentForms)) {
    return res;
  }
  else {
    const segmentPromises = segmentForms.map((el) => {
      return new Promise<void>((resolve) => {
        const id = el.key;
        const nameInput = document.getElementById("name_input" + id) as HTMLInputElement;
        const colorInput = document.getElementById("color_input" + id) as HTMLInputElement;
        const pictoInput = document.getElementById("picto_input" + id) as HTMLInputElement;
        const stockInput = document.getElementById("stock_input" + id) as HTMLInputElement;
        const existingImageInput = document.getElementById("existingImage_input" + id) as HTMLSelectElement;
        const nbInput = document.getElementById("nb_input" + id) as HTMLInputElement;
        const file = pictoInput.files![0];

        if (file != null) {
          const reader = new FileReader();
          reader.onload = function (event) {
            const base64String = (event.target as FileReader).result as string;
            res.push({
              name: nameInput.value.toLowerCase(),
              color: colorInput.value,
              picto: base64String,
              image: file.name,
              stock: parseInt(stockInput.value),
              nb: parseInt(nbInput.value),
              ordre: parseInt(id!)
            });
            resolve();
          };
          reader.readAsDataURL(file);
        } else {
          res.push({
            name: nameInput.value.toLowerCase(),
            color: colorInput.value,
            picto: "noChange",
            image: existingImageInput.value,
            stock: parseInt(stockInput.value),
            nb: parseInt(nbInput.value),
            ordre: parseInt(id!)
          });
          resolve();
        }
      });
    });

    await Promise.all(segmentPromises);
    return res;
  }
}

function checkInputs(segmentForms : JSX.Element[]) : boolean {
  if (segmentForms.length < 2) {
    alert('Il n\'y a pas assez de segments renseignés');
    return false;
  }
  else {
    for (const seg of segmentForms) {
      const i = seg.key;
      const nameInput = document.getElementById("name_input" + i) as HTMLInputElement;
      const colorInput = document.getElementById("color_input" + i) as HTMLInputElement;
      const stockInput = document.getElementById("stock_input" + i) as HTMLInputElement;
      const nbInput = document.getElementById("nb_input" + i) as HTMLInputElement;

      if (nameInput.value === "") {
        alert('Un nom de segment est manquant');
        return false;
      }
      if (colorInput.value === "") {
        alert('Une couleur de segment est manquante');
        return false;
      }
      if (stockInput.value === "" || parseInt(stockInput.value) < 1) {
        alert('Un stock de segment est manquant ou est inférieur à 1');
        return false;
      }
      if (nbInput.value === "" || parseInt(nbInput.value) < 1) {
        alert('Une chance de segment est manquante ou est inférieure à 1');
        return false;
      }
    }
    return true;
  }
}

function SegmentForm(key: number, existingImages: string[], infos?: SegmentInfo) {
  if (infos) {
    return (
      <div className="form-card" key={key} id={"formContainer" + key.toString}>
        <div className="first-row">
          <input defaultValue={infos.name} className="form-name" type="text" id={"name_input" + key} placeholder="Bonbons..." />
          <input defaultValue={infos.color} className="form-color" type="color" id={"color_input" + key} />
        </div>
        <div className="second-row">
          <div className="stock-container">
            <span>Stock :</span>
            <input defaultValue={infos.stock} className="form-stock" type="number" id={"stock_input" + key} />
          </div>
          <div className="chances-container">
            <span>Chances :</span>
            <input defaultValue={infos.nb} className="form-chances" type="number" id={"nb_input" + key} />
          </div>
        </div>
        <div className="third-row">
          <select defaultValue={infos.picto} name="existingImageInput" className="form-image-select" id={"existingImage_input" + key} onChange={(e) => { setNewSelectImage((e.target as HTMLSelectElement).value, key) }}>
            <option value="noChange">-- Pas de changement --</option>
            {existingImages.map((el, index) => { return <option key={index} value={el}>{el}</option> })}
          </select>
        </div>

        <div className="fourth-row">
          <label className="form-picto-label">
            <input className="form-picto" type="file" id={"picto_input" + key} onChange={(e) => setNewInputImage((e.target as HTMLInputElement).files![0], key)} />
            <img className="current-picto" src={"https://rdlfbingo.dev.mtv-nprd.cloud.bpifrance.fr/images/" + infos.picto} alt="" id={"currentPicto" + key} />
          </label>
        </div>
      </div>
    )
  }
  else {
    return (
      <div className="form-card" key={key} id={"formContainer" + key.toString}>
        <div className="first-row">
          <input className="form-name" type="text" id={"name_input" + key} placeholder="Bonbons..." />
          <input className="form-color" type="color" id={"color_input" + key} />
        </div>
        <div className="second-row">
          <div className="stock-container">
            <span>Stock :</span>
            <input className="form-stock" type="number" id={"stock_input" + key} />
          </div>
          <div className="chances-container">
            <span>Chances :</span>
            <input defaultValue="1" className="form-chances" type="number" id={"nb_input" + key} />
          </div>
        </div>
        <div className="third-row">
          <select name="existingImageInput" className="form-image-select" id={"existingImage_input" + key} onChange={(e) => { setNewSelectImage((e.target as HTMLSelectElement).value, key) }}>
            <option value="noChange">-- Pas de changement --</option>
            {existingImages.map((el, index) => { return <option key={index} value={el}>{el}</option> })}
          </select>
        </div>

        <div className="fourth-row">
          <label className="form-picto-label">
            <input className="form-picto" type="file" id={"picto_input" + key} onChange={(e) => setNewInputImage((e.target as HTMLInputElement).files![0], key)} />
            <img className="current-picto" src={pngPlaceholder} alt="" id={"currentPicto" + key} />
          </label>
        </div>
      </div>
    )
  }
}

export default SegmentForm;