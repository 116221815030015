import { JSX } from "react/jsx-runtime";
import { SegmentInfo } from '../../Type/SegmentInfo';
import { useSelector } from "react-redux";
import { useReplaceStore } from "../../Actions/changeStore";
import { useEffect, useState } from "react";
import { calcBaseCoordinates, genSegmentStyle } from "./Segment.utils";
import { backendURL } from "../../Config/vars";

// this file gets the config from the backend and generates the wheel

function Segment() {
    const [body, setBody] = useState([] as SegmentInfo[]);

    // fetch segments from the db
    useEffect(() => {
        async function getSegments() {
            try {
                // api call to get the data
                const segRes = await fetch(backendURL + "/getSegments", {
                    method: "GET",
                    headers: {
                        'Content-type': "application/JSON",
                    }
                });
                segRes.text().then(text => {
                    // we receive every segment in the body
                    const body = JSON.parse(text);
                    setBody(body);
                });
            } catch (e) {
                console.log("error when fetching from db", e);
            }
        }
        getSegments();
    } ,[]);

    useReplaceStore({segmentsArray: body});

    const rawSegments : SegmentInfo[] = useSelector((state: any) => state.segments.segmentsInfos.segmentsArray);
    const mySegments = rawSegments.filter((seg: SegmentInfo) => seg.stock > 0);
    let totalSegments = 0;
    mySegments.forEach((seg: SegmentInfo) => {totalSegments += seg.nb;});

    // we create a segment for each item found in the config file
    const segments: JSX.Element[] = [];
    // we do it twice parce que c'est plus joli
    for(let i = 0; i < 2; i++) {
        let index = 0;
        for(let iSeg = 0; iSeg < mySegments.length; iSeg++) {
            const seg = mySegments[iSeg];
            const segmentId = i * totalSegments + index + (seg.nb / 2);
            const [leftX, rightX] = calcBaseCoordinates(totalSegments, seg.nb);
            segments.push(
                <div key={segmentId} className={"segment " + segmentId.toString()} style={genSegmentStyle(
                    segmentId,
                    seg.color,
                    totalSegments * 2,
                    leftX,
                    rightX)}>
                    <img src={"https://rdlfbingo.dev.mtv-nprd.cloud.bpifrance.fr/images/" + seg.picto} alt={"Example " + segmentId.toString()} draggable='false' style={{ maxWidth: '30%', maxHeight: '40%', width: 'auto', height: 'auto' }} />
                    {seg.name}
                </div>
            );
            index += seg.nb;
        };
    }

    return (
        <>
            {segments}
        </>
    )
}

export default Segment;