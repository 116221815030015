import React, { Fragment, ReactNode, useState } from 'react';
import { Navigate } from 'react-router-dom';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTwitter, faLinkedin } from '@fortawesome/free-brands-svg-icons';
import Confetti from 'react-dom-confetti';

import './Wheel.scss';
import Segment from '../Segment/Segment';
import { backendURL } from '../../Config/vars';
import { SegmentInfo } from '../../Type/SegmentInfo';
import { useSelector } from 'react-redux';

declare const dsLib: any;

function SpinWheel({ setWinningSegmentText, setImageSource, setConfetti  }: { setWinningSegmentText: (text: string) => void, setImageSource: (text: string) => void,setConfetti: (isActive: boolean) => void }, segmentsInfo: SegmentInfo[]) {

    let wheel = document.querySelector('.wheel') as HTMLDivElement;
    const segments = document.querySelectorAll('.segment');

    var numSegments = 0;
    segmentsInfo.forEach((seg: SegmentInfo) => {numSegments += seg.nb;});

    // on fait entre 3 et 7 tours complets
    let nbTours = (Math.floor(Math.random() * 4) + 3) * 360;
    // on ajoute un angle aléatoire pour l'arrêt
    let endAngle = Math.floor(Math.random() * 360);
    wheel.style.transform = `rotate(${- nbTours - endAngle}deg)`;

    const spinBtn = document.querySelector('.spinBtn');
        if (spinBtn)
            spinBtn.classList.add('rotating');

    setTimeout(async () => {
        const segmentAngle = 180 / numSegments;

        let segmentIndex = 0;
        let i = 0;

        segmentsInfo.forEach((seg: SegmentInfo, currentSegment) => {
            const truEndAngle = endAngle % 180;

            const segmentStartAngle = i * segmentAngle + segmentAngle / 2;
            const segmentEndAngle = (i + seg.nb) * segmentAngle + segmentAngle / 2;
            i += seg.nb;
            if (truEndAngle >= segmentStartAngle && truEndAngle < segmentEndAngle) {
                segmentIndex = currentSegment;
            }
        });

        const segment = segments[segmentIndex] as HTMLDivElement;
        const segmentText = segment.textContent?.trim();
        const segmentImageSource = segment.querySelector('img');

        await fetch(backendURL + "/decrementStockSegment/" + segmentText, {
            method: "POST",
            headers: {
                'Content-type': "application/JSON",
            }
        });

        setWinningSegmentText(segmentText || '');
        setImageSource(segmentImageSource?.src || '');

        new dsLib.Alert('alert_info').show();
        setConfetti(true); 
        if (spinBtn)
            spinBtn.classList.remove('rotating');
    }, 3200);
}

function WinAlert({ winningSegmentText, imageSource }: { winningSegmentText: string, imageSource: string }): ReactNode {
    return (
        <button style={{border: 'none', background: 'none'}}> 
        Vous avez gagné : {winningSegmentText} <img src={imageSource} alt={winningSegmentText} draggable="false" />
        </button>
    );
}


export const Wheel = () => {    
    document.body.classList.add("wheel-bg-color");

    const [winningSegmentText, setWinningSegmentText] = useState('');
    const [imageSource, setImageSource] = useState('');
    const [confetti, setConfetti] = useState(false);
    const [wheelHasSpun, setWheelHasSpun] = useState(false);

    const rawSegmentsInfo : SegmentInfo[] = useSelector((state: any) => state.segments.segmentsInfos.segmentsArray);
    const segmentsInfo = rawSegmentsInfo.filter((seg: SegmentInfo) => seg.stock > 0);

    const leftConfig = {
        angle: 90,
        spread: 360,
        startVelocity: 20,
        elementCount: 200,
        dragFriction: 0.12,
        duration: 3000,
        delay: 0,
        width: "10px",
        height: "10px",
        colors: ["#a864fd", "#29cdff", "#20e39e", "#e0f1fc", "#b5ffb5"]
      };
      const rightConfig = {
        angle: 270,
        spread: 360,
        startVelocity: 20,
        elementCount: 200,
        dragFriction: 0.12,
        duration: 3000,
        delay: 0,
        width: "10px",
        height: "10px",
        colors: ["#a864fd", "#29cdff", "#20e39e", "#e0f1fc", "#b5ffb5"]
      };

    const handleSpinClick = () => {
        const alreadyPlayed = localStorage.getItem('hasPlayed') === 'true';
        if (alreadyPlayed) {
            alert('Vous avez déjà joué, vous ne pouvez pas rejouer !');
        }
        else {
            localStorage.setItem('hasPlayed', 'true');
            if (!wheelHasSpun) {
                SpinWheel({ setWinningSegmentText, setImageSource, setConfetti }, segmentsInfo);
            }

            setWheelHasSpun(true);
        }
    };

    return ( 
        <Fragment>
            {localStorage.getItem('answeredCorrectly') === 'true' ? null : <Navigate to ='/'/>}
            <header className='header'>
                <h1 className="pink-border">LA GRANDE ROUE (BINGO)</h1>
                {/* <div className="square-container">
               HIT THE BUTTON
                </div> */}
            
            </header>
            {/* Conffetti droite et gauche */}

            <div className="confetti-left">
                <Confetti active={confetti} config={leftConfig} />
            </div>
            <div className="confetti-right">
                <Confetti active={confetti} config={rightConfig} />
            </div>

            {/* <main> */}
                                 
                <div className='container'>
                    <button className="spinBtn" onClick={handleSpinClick}>
                    </button>
                    <div className="arrow"></div>
                    <div className='wheel'>                    
                        <Segment />
                    </div>
                    <section>
                    <div id="alert_info" className="ds-alert ds-alert--info wheel-notif" aria-label="Félicitations !" role="alert" tabIndex={0}>
                        <div className="ds-alert-group">
                            <div className="ds-alert-group__header">
                                <h6 className='Congrats'>Félicitations !</h6>
                                <button id="close_alert_info">
                                    <span className="ds-icon" aria-hidden="true">close</span>
                                </button>
                            </div>
                            <div className="ds-alert-group__body">
                                <p className="ds-body-2--regular">{WinAlert({ winningSegmentText, imageSource })}</p>
                                <div className="social-icons">
                                    <div className='socials-row'>
                                        <a href="https://twitter.com/bpifrance_io" className="twitter-icon">
                                            <FontAwesomeIcon icon={faTwitter} size="2x" style={{ color: "#1DA1F2" }} />
                                        </a>
                                        <a href="https://www.linkedin.com/company/bpifrance-io/" className="linkedin-icon">
                                            <FontAwesomeIcon icon={faLinkedin} size="2x" style={{ color: "#0077b5" }} />
                                        </a>
                                    </div>
                                    <a href="https://talents.bpifrance.fr/nos-opportunites/?equipe=digital-it&criteres=digital-it" className='nos-offres'>Nos offres</a>
                                </div>
                            </div>
                        </div>
                    </div>
                    </section>
                </div>
                { <div className='spin-button'>
                </div> }
                <footer className='footer'>
                    <img src="logo_io.png" alt="Logo" className='bpifrance-logo' style={{ width: '150px', height: 'auto' }} />
                </footer>            
        </Fragment>
    );
}
