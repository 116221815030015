function genSegmentStyle(seg_number: number, seg_color: string, total: number, leftX: number, rightX: number) {
    // generates a CSSProperties elements to add variables to our style section of the HTML elements
    return { 
        "--i": seg_number,
        "--clr": seg_color,
        "--n": total,
        "--leftX": leftX.toString() + "%",
        "--rightX": rightX.toString() + "%"} as React.CSSProperties;
}

function calcBaseCoordinates(nbSegments: number, chances: number) {
    // some maths
    // we are building triangles bigger than the wheel so we only see the base of
    // the triangle and it looks like a segment thanks to the overflow hidden attribute
    // the triangles have 2 equal sides and we want an angle of PI / nbSegments
    // so we can calculate the length of the base

    const triangleHeight = 60; // the height in % in the CSS
    const offset = Math.tan(Math.PI / nbSegments / 2 * chances) * triangleHeight;

    const leftX : number = 50 - Math.round(offset);
    const rightX : number = 50 + Math.round(offset);

    return [leftX, rightX];
}

export {genSegmentStyle, calcBaseCoordinates};