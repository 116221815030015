import React from 'react';
import { Wheel } from './Components/Wheel/Wheel';


import { Routes, Route} from 'react-router-dom'
import 'font-awesome/css/font-awesome.min.css';
import { Provider } from 'react-redux';
import { store } from './Reducer/store';
import AppBack from './Components/AppBack/AppBack';

const App: React.FC = () => {

  return (
    <Provider store={store}>
      <Routes>
        <Route path="/" element={<Wheel />} />
        <Route path="/custom" element={<AppBack/>} />
      </Routes>
    </Provider>
  );
};

export default App;